import newPurchaseOrderTwo from './new-distributor/new-purchase-order-two'

export default [
  // 新招商经销管理
  // 经销商管理
  {
    path: 'monthly-payment-details/:dateId/:classId/:mftId/:cardName/:cardId/:bankName',
    name: 'monthly-payment-details',
    component: () => import('@/views/new-distributor/components/new-purchase-order/monthly-payment/monthly-payment-details.vue'),
    meta: { title: '当月付款详情' }, // 旧
    props: true
  },
  {
    path: 'details-of-new-dealers/:id',
    name: 'details-of-new-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/agent-dealer/details-of-new-dealers.vue'),
    meta: { title: '代理经销商详情' }, // 旧
    props: true
  },
  {
    path: 'details-new-of-new-dealers/:id',
    name: 'details-new-of-new-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/new-agent-dealer/details-of-new-dealers.vue'),
    meta: { title: '代理经销商详情' }, // 新代理
    props: true
  },
  {
    path: 'edit-of-new-dealers/:id?',
    name: 'edit-of-new-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/agent-dealer/edit-of-new-dealers.vue'),
    meta: { title: '新增/编辑' }, // 代理
    props: true
  },
  {
    path: 'edit-of-new-direct-dealers/:id?',
    name: 'edit-of-new-direct-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/direct-dealers/edit-of-new-direct-dealers.vue'),
    meta: { title: '新增/编辑' }, // 直营
    props: true
  },
  {
    path: 'details-of-new-direct-dealers/:id',
    name: 'details-of-new-direct-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/direct-dealers/details-of-new-direct-dealers.vue'),
    meta: { title: '直营经销商详情' }, // 直营
    props: true
  },
  {
    path: 'details-new-of-new-direct-dealers/:id',
    name: 'details-new-of-new-direct-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/new-direct-dealers/details-of-new-direct-dealers.vue'),
    meta: { title: '直营经销商详情' }, // 新直营
    props: true
  },
  {
    path: 'edit-new-of-new-dealers/:id?',
    name: 'edit-new-of-new-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/new-agent-dealer/edit-of-new-dealers.vue'),
    meta: { title: '新增/编辑' }, // 新代理
    props: true
  },
  {
    path: 'edit-new-of-new-direct-dealers/:id?',
    name: 'edit-new-of-new-direct-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/new-direct-dealers/edit-of-new-direct-dealers.vue'),
    meta: { title: '新增/编辑' }, // 新直营
    props: true
  },
  {
    path: 'edit-level-new-dealers/:id',
    name: 'edit-level-new-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/new-agent-dealer/edit-level-new-dealers.vue'),
    meta: { title: '级别' },
    props: true
  },
  {
    path: 'edit-merchants-new-dealers/:id',
    name: 'edit-merchants-new-dealers',
    component: () => import('@/views/new-distributor/components/dealer-management/new-agent-dealer/edit-merchants-new-dealers.vue'),
    meta: { title: '扶商' },
    props: true
  },
  // // 经销商结算
  {
    path: 'details-dealer-settlement/:id/:dateId',
    name: 'details-dealer-settlement',
    component: () => import('@/views/new-distributor/components/dealer-management/dealer-settlement/details-dealer-settlement.vue'),
    meta: { title: '经销商结算详情' },
    props: true
  },
  // 收款账户
  {
    path: 'details-account-list-new/:id',
    name: 'details-account-list-new',
    component: () => import('@/views/new-distributor/components/collection-account/details-account-list-new.vue'),
    meta: { title: '账户详情' },
    props: true
  },
  {
    path: 'edit-account-list-new/:id?',
    name: 'edit-account-list-new',
    component: () => import('@/views/new-distributor/components/collection-account/edit-account-list-new.vue'),
    meta: { title: '编辑' }, // 账户列表编辑
    props: true
  },
  {
    path: 'edit-account-flow-new/:id?',
    name: 'edit-account-flow-new',
    component: () => import('@/views/new-distributor/components/collection-account/edit-account-flow-new.vue'),
    meta: { title: '编辑/新增' }, // 账户流水编辑
    props: true
  },
  {
    path: 'relation-account-list-new/:id?',
    name: 'relation-account-list-new',
    component: () => import('@/views/new-distributor/components/collection-account/relation-account-list-new.vue'),
    meta: { title: '关联' },
    props: true
  },
  {
    path: 'details-order-payment/:id',
    name: 'details-order-payment',
    component: () => import('@/views/new-distributor/components/collection-account/details-order-payment.vue'),
    meta: { title: '详情' },
    props: true
  },
  // 货物管理
  // // 出入库
  {
    path: 'details-new-warehousing-record/:id/:type',
    name: 'details-new-warehousing-record',
    component: () => import('@/views/new-distributor/components/picking-management/new-warehousing-record/details-new-warehousing-record.vue'),
    meta: { title: '详情' }, // 出入库详情
    props: true
  },
  {
    path: 'add-new-stock-out',
    name: 'add-new-stock-out',
    component: () => import('@/views/new-distributor/components/picking-management/new-warehousing-record/add-new-stock-out.vue'),
    meta: { title: '新增' }, // 出入库新增
    props: true
  },
  // // 仓库列表
  {
    path: 'new-warehouse-store/:id',
    name: 'new-warehouse-store',
    component: () => import('@/views/new-distributor/components/picking-management/new-warehouse-list/new-warehouse-store.vue'),
    meta: { title: '库存' }, // 库存
    props: true
  },
  {
    path: 'details-new-warehouse-management/:id',
    name: 'details-new-warehouse-management',
    component: () => import('@/views/new-distributor/components/picking-management/new-warehouse-list/details-new-warehouse-management.vue'),
    meta: { title: '详情' }, // 仓库详情
    props: true
  },
  {
    path: 'edit-new-warehouse-management/:id?',
    name: 'edit-new-warehouse-management',
    component: () => import('@/views/new-distributor/components/picking-management/new-warehouse-list/edit-new-warehouse-management.vue'),
    meta: { title: '编辑' }, // 编辑
    props: true
  },
  // 产品管理
  // // 产品列表
  {
    path: 'details-new-product-order/:id',
    name: 'details-new-product-order',
    component: () => import('@/views/new-distributor/components/new-product-management/product-list/details-new-product-order.vue'),
    meta: { title: '详情' }, // 产品详情 用户层
    props: true
  },
  {
    path: 'details-new-product/:id',
    name: 'details-new-product',
    component: () => import('@/views/new-distributor/components/new-product-management/product-list/details-new-product.vue'),
    meta: { title: '详情' }, // 产品内容详情 系统层
    props: true
  },
  {
    path: 'edit-new-product-cost-price/:id',
    name: 'edit-new-product-cost-price',
    component: () => import('@/views/new-distributor/components/new-product-management/product-list/edit-new-product-cost-price.vue'),
    meta: { title: '成本价' },
    props: true
  },
  {
    path: 'edit-new-product-agency-price/:id/:ordName',
    name: 'edit-new-product-agency-price',
    component: () => import('@/views/new-distributor/components/new-product-management/product-list/edit-new-product-agency-price.vue'),
    meta: { title: '代理价' },
    props: true
  },
  {
    path: 'edit-new-products/:id?',
    name: 'edit-new-products',
    component: () => import('@/views/new-distributor/components/new-product-management/product-list/edit-new-products.vue'),
    meta: { title: '新建/编辑' },
    props: true
  },
  // 预存货款
  {
    path: 'details-new-advance-distributor/:id',
    name: 'details-new-advance-distributor',
    component: () => import('@/views/new-distributor/components/advance-payment/distributor-payment/details-advance.vue'),
    meta: { title: '预存货款详情' }, // 经销商
    props: true
  },
  {
    path: 'detailed-new-advance-distributor/:id',
    name: 'detailed-new-advance-distributor',
    component: () => import('@/views/new-distributor/components/advance-payment/distributor-payment/detailed-advance.vue'),
    meta: { title: '预存货款明细' }, // 经销商
    props: true
  },
  {
    path: 'edit-new-advance-distributor/:id',
    name: 'edit-new-advance-distributor',
    component: () => import('@/views/new-distributor/components/advance-payment/distributor-payment/edit-advance.vue'),
    meta: { title: '编辑' }, // 经销商预存货款
    props: true
  },
  {
    path: 'finance-new-advance-distributor/:id',
    name: 'finance-new-advance-distributor',
    component: () => import('@/views/new-distributor/components/advance-payment/distributor-payment/finance-advance.vue'),
    meta: { title: '财务审核' }, // 经销商预存货款
    props: true
  },
  {
    path: 'details-new-advance-manufacturer/:id',
    name: 'details-new-advance-manufacturer',
    component: () => import('@/views/new-distributor/components/advance-payment/manufacturer-payment/details-advance.vue'),
    meta: { title: '预存货款详情' }, // 厂商
    props: true
  },
  {
    path: 'detailed-new-advance-manufacturer/:id',
    name: 'detailed-new-advance-manufacturer',
    component: () => import('@/views/new-distributor/components/advance-payment/manufacturer-payment/detailed-advance.vue'),
    meta: { title: '预存货款明细' }, // 厂商
    props: true
  },
  {
    path: 'edit-new-advance-manufacturer/:id?',
    name: 'edit-new-advance-manufacturer',
    component: () => import('@/views/new-distributor/components/advance-payment/manufacturer-payment/edit-advance.vue'),
    meta: { title: '审核' }, // 厂商
    props: true
  },
  {
    path: 'edit-part-new-advance-manufacturer/:id',
    name: 'edit-part-new-advance-manufacturer',
    component: () => import('@/views/new-distributor/components/advance-payment/manufacturer-payment/edit-part-advance.vue'),
    meta: { title: '编辑' }, // 厂商
    props: true
  },
  // // 经销商订单
  {
    path: 'details-new-customer-service-review/:id',
    name: 'details-new-customer-service-review',
    component: () => import('@/views/new-distributor/components/dealer-order/customer-service-review/details-customer-service-review.vue'),
    meta: { title: '审核详情' },
    props: true
  },
  {
    path: 'edit-new-customer-service-review/:id',
    name: 'edit-new-customer-service-review',
    component: () => import('@/views/new-distributor/components/dealer-order/customer-service-review/edit-customer-service-review.vue'),
    meta: { title: '修改订单' },
    props: true
  },
  {
    path: 'details-new-history-order/:id',
    name: 'details-new-history-order',
    component: () => import('@/views/new-distributor/components/dealer-order/history-order/details-history-order.vue'),
    meta: { title: '历史订单详情' },
    props: true
  },
  {
    path: 'details-new-payment-order/:id',
    name: 'details-new-payment-order',
    component: () => import('@/views/new-distributor/components/dealer-order/stay-payment-order/details-payment-order.vue'),
    meta: { title: '待付款详情' },
    props: true
  },
  {
    path: 'details-new-financial-review/:id',
    name: 'details-new-financial-review',
    component: () => import('@/views/new-distributor/components/dealer-order/financial-review/details-financial-review.vue'),
    meta: { title: '财务审核详情' },
    props: true
  },
  {
    path: 'details-new-warehous-delivery/:id/:storeId/:storeStatus',
    name: 'details-new-warehous-delivery',
    component: () => import('@/views/new-distributor/components/dealer-order/warehous-delivery/details-warehous-delivery.vue'),
    meta: { title: '仓库发货详情' },
    props: true
  },
  {
    path: 'details-new-warehous-delivery-transfer/:id/:storeId/:storeStatus',
    name: 'details-new-warehous-delivery-transfer',
    component: () => import('@/views/new-distributor/components/dealer-order/warehous-delivery/details-warehous-delivery-transfer.vue'),
    meta: { title: '仓库调货详情' },
    props: true
  },
  {
    path: 'details-new-returnOrder-finance/:id',
    name: 'details-new-returnOrder-finance',
    component: () => import('@/views/new-distributor/components/dealer-order/return-order/details-returnOrder-finance.vue'),
    meta: { title: '退货财务' },
    props: true
  },
  {
    path: 'details-new-returnOrder-warehouse/:id',
    name: 'details-new-returnOrder-warehouse',
    component: () => import('@/views/new-distributor/components/dealer-order/return-order/details-returnOrder-warehouse.vue'),
    meta: { title: '退货仓库' },
    props: true
  },
  {
    path: 'details-new-delivery-management/:id/:storeId/:storeStatus',
    name: 'details-new-delivery-management',
    component: () => import('@/views/new-distributor/components/dealer-order/delivery-management/details-delivery-management.vue'),
    meta: { title: '仓库发货详情' },
    props: true
  },
  // // 厂商名单
  {
    path: 'details-list-of-manufacturers/:id',
    name: 'details-list-of-manufacturers',
    component: () => import('@/views/new-distributor/components/list-of-manufacturers/details-list-of-manufacturers.vue'),
    meta: { title: '详情' },
    props: true
  },
  {
    path: 'edit-list-of-manufacturers/:id?',
    name: 'edit-list-of-manufacturers',
    component: () => import('@/views/new-distributor/components/list-of-manufacturers/edit-list-of-manufacturers.vue'),
    meta: { title: '编辑/新增' },
    props: true
  },
  // // 采购订单
  {
    path: 'details-new-purchase-finance/:id/:type?',
    name: 'details-new-purchase-finance',
    component: () => import('@/views/new-distributor/components/purchase-order/purchaseList/details-purchase-finance.vue'),
    meta: { title: '财务详情' },
    props: true
  },
  {
    path: 'details-new-purchase-warehouse/:id',
    name: 'details-new-purchase-warehouse',
    component: () => import('@/views/new-distributor/components/purchase-order/purchaseList/details-purchase-warehouse.vue'),
    meta: { title: '仓库详情' },
    props: true
  },
  {
    path: 'new-set-order',
    name: 'new-set-order',
    component: () => import('@/views/new-distributor/components/purchase-order/new-procurement/set-order.vue'),
    meta: { title: '采购清单', rekeepAlive: true, scrollTop: 0, name: 'newSetOrder' },
    props: true
  },
  {
    path: 'details-new-consolidated-payment/:id',
    name: 'details-new-consolidated-payment',
    component: () => import('@/views/new-distributor/components/purchase-order/consolidated-payment/details-consolidated-payment.vue'),
    meta: { title: '合并支付详情' },
    props: true
  },
  {
    path: 'details-new-warehouse-dispatch/:id/:storeId',
    name: 'details-new-warehouse-dispatch',
    component: () => import('@/views/new-distributor/components/purchase-order/warehouse-dispatch/details-warehouse-dispatch.vue'),
    meta: { title: '仓库派单详情' },
    props: true
  },
  {
    path: 'edit-new-warehouse-dispatch/:id/:storeId',
    name: 'edit-new-warehouse-dispatch',
    component: () => import('@/views/new-distributor/components/purchase-order/warehouse-dispatch/edit-warehouse-dispatch.vue'),
    meta: { title: '仓库派单编辑' },
    props: true
  },
  {
    path: 'new-place-order/:id?/:storeId?',
    name: 'new-place-order',
    component: () => import('@/views/new-distributor/components/purchase-order/warehouse-dispatch/place-order.vue'),
    meta: { title: '采购清单' },
    props: true
  },
  // // 经销商业绩
  // // // 出货业绩
  {
    path: 'details-shipment-performance/:id',
    name: 'details-shipment-performance',
    component: () => import('@/views/new-distributor/components/dealer-performance/shipment-performance/details-shipment-performance.vue'),
    meta: { title: '业绩详情' },
    props: true
  },
  {
    path: 'details-stock-with-goods/:id',
    name: 'details-stock-with-goods',
    component: () => import('@/views/new-distributor/components/dealer-performance/shipment-performance/details-stock-with-goods.vue'),
    meta: { title: '进货详情' },
    props: true
  },
  {
    path: 'details-product-ranking/:id',
    name: 'details-product-ranking',
    component: () => import('@/views/new-distributor/components/dealer-performance/shipment-performance/details-product-ranking.vue'),
    meta: { title: '产品排名详情' },
    props: true
  },
  // // 扶商业绩
  // // // 业绩列表
  {
    path: 'details-new-dis-performanceList/:id/:type?',
    name: 'details-new-dis-performanceList',
    component: () => import('@/views/new-distributor/components/supporting-business-performance/performanceList/details-new-dis-performanceList.vue'),
    meta: { title: '业绩详情' },
    props: true
  },
  {
    path: 'new-details-dealer-performance/:id',
    name: 'new-details-dealer-performance',
    component: () => import('@/views/new-distributor/components/supporting-business-performance/performanceList/details-dealer-performance.vue'),
    meta: { title: '经销商业绩详情', rekeepAlive: true, scrollTop: 0, name: 'newDetailsDealerPerformance' },
    props: true
  },
  // 员工自购
  {
    path: 'self-purchase-audit/:id',
    name: 'new-self-purchase-audit',
    component: () => import('@/views/new-distributor/components/employee-self-purchase/self-purchase-audit.vue'),
    meta: { title: '自购订单审核' },
    props: true
  },
  {
    path: 'self-purchase-financial-review/:id',
    name: 'new-self-purchase-financial-review',
    component: () => import('@/views/new-distributor/components/employee-self-purchase/self-purchase-financial-review.vue'),
    meta: { title: '自购财务审核' },
    props: true
  },
  {
    path: 'self-purchase-order-shipment/:id',
    name: 'new-self-purchase-order-shipment',
    component: () => import('@/views/new-distributor/components/employee-self-purchase/self-purchase-order-shipment.vue'),
    meta: { title: '自购订单发货' },
    props: true
  },
  {
    path: 'details-of-resale/:id',
    name: 'new-details-of-resale',
    component: () => import('@/views/new-distributor/components/employee-self-purchase/details-of-resale.vue'),
    meta: { title: '转转卖单详情' },
    props: true
  },
  {
    path: 'details-of-redeposit/:id',
    name: 'new-details-of-redeposit',
    component: () => import('@/views/new-distributor/components/employee-self-purchase/details-of-redeposit.vue'),
    meta: { title: '转预存款详情' },
    props: true
  },
  {
    path: 'payment-for-goods/:id/:dataId/:type?',
    // name: 'payment-for-goods',
    component: () => import('@/views/processSignOff/components/payment-for-goods/payment-for-goods.vue'),
    meta: { title: '合并支付流程详情' },
    props: true
  },
  // 新采购订单
  ...newPurchaseOrderTwo
]
